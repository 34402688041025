import { Link } from "gatsby"
import React from "react"
import { COURSES, EBOOKS } from "../constants"
import { useLanguage, useParams } from "../hooks"
import { t } from "../utils/t"
import { Main, Row, RowAndSpaceBetween, Text } from "../components/atomics"
import Page from "../components/Pages/Page"
import colors from "../colors"

function Bonus() {
  const { language } = useLanguage("pt")
  const { query } = useParams()
  const [, app] = query.split("app=")
  const CUPOM = "FLYNOW40"
  const TAXFREE = "40"
  return (
    <Page title="Bônus" titlePage="Bônus" pageId="bonus" showEbooks>
      <Main>
        <div>
          <h1>
            Estamos super felizes por você ter escolhido se tornar Premium!
          </h1>
          <Text style={{ marginTop: 10 }}>
            Por isso, separamos <b>4 BÔNUS SURPRESAS</b> super especiais para que você
            continue na sua jornada rumo à sua melhor versão:
            <br></br>
            <br></br>


            ✔️ Utilize o cupom {CUPOM} para obter <b> {TAXFREE}% de desconto</b> em nosso curso <b>DOMINE SEUS HÁBITOS</b>:
    A chave para uma vida
    extraordinária.
    <br />
    ✔️ Utilize o cupom {CUPOM} para obter <b>{TAXFREE}% de desconto</b> em qualquer um de nossos <b>ebooks</b>.
    <br />
    ✔️ <b>25% OFF na assinatura</b> de qualquer um dos nossos outros apps.
    <br />
    ✔️ Entre em nossa <b>Comunidade do Flynow</b> gratuitamente!

          </Text>
        </div>
      </Main>


      <h2 style={{ color: colors.primary, marginBottom: 10 }}>
      Comunidade Flynow (Grátis)
      </h2>
      <Text style={{ marginTop: 10, fontSize: "1rem" }}>
        Criamos uma comunidade exclusiva no WhatsApp onde você poderá fazer networking com outros usuários que estão na
        mesma jornada de desenvolvimento pessoal que você e compartilhar sua rotina diária, dicas, aprendizados, etc.
        <br />
        Além disso, no grupo da Comunidade Flynow, você poderá tirar dúvidas sobre o uso do app e descobrir dicas e
        truques
        para aproveitar ao máximo todas as funcionalidades. <br />
      </Text>
      <RowAndSpaceBetween>
        <a  className="button-vermais" href="https://chat.whatsapp.com/IA42Lm64lqYHSzuGwXKjoV">ENTRAR NA COMUNIDADE!</a>
      </RowAndSpaceBetween>
      <br/>

      <h2 style={{ color: colors.primary, marginBottom: 10 }}>
        Nossos Cursos com {TAXFREE}% de desconto
      </h2>
      {COURSES.map(i => (
        <div style={{ marginTop: 20 }}>
          <RowAndSpaceBetween style={{ justifyContent: "flex-start" }}>
            <img src={i.img} alt="aplicativo" height={150} />
            <div style={{ minWidth: 330, marginLeft: 10 }}>
              <h3>{i.title}</h3>
              <Text style={{ marginTop: 10, fontSize: "1rem" }}>
                {i.description}
              </Text>
              <Link className="button-vermais" to={i.url + query}>
                OBTER CURSO! {TAXFREE}%OFF
              </Link>
            </div>
          </RowAndSpaceBetween>
        </div>
      ))}

      <div style={{ marginTop: 40 }}></div>
      <h2 style={{ color: colors.primary, marginBottom: 10 }}>
        Nossos Ebooks com {TAXFREE}% de desconto
      </h2>
      {EBOOKS.map(i => (
        <div style={{ marginTop: 20 }}>
          <RowAndSpaceBetween style={{ justifyContent: "flex-start" }}>
            <img src={i.img} alt="aplicativo" height={150} />
            <div style={{ minWidth: 330, marginLeft: 10 }}>
              <h3>{i.title}</h3>
              <Text style={{ marginTop: 10, fontSize: "1rem" }}>
                {i.description}
              </Text>
              <Link className="button-vermais" to={i.url + query}>
                OBTER EBOOK! {TAXFREE}%OFF
              </Link>
            </div>
          </RowAndSpaceBetween>
        </div>
      ))}

      <div style={{ marginTop: 40 }}></div>
      <h2 style={{ color: colors.primary }}>Nossos Apps com 25% de desconto</h2>
      {(!app || !app.includes("productivity")) && (
        <div style={{ marginTop: 30 }}>
          <RowAndSpaceBetween>
            <img
              src={require("../assets/images/screen-flynow.png")}
              alt="aplicativo"
              height={150}
            />
            <div style={{ minWidth: 330 }}>
              <h3>{t("flynow_productivity", language)}</h3>
              <Text style={{ marginTop: 10, fontSize: "1rem" }}>
                {t("flynow_productivity_desc", language)}
              </Text>
              <Row>
                <Link
                  className="button-vermais"
                  to={"/apps/productivity" + query}
                >
                  BAIXAR APP
                </Link>
                <Link
                  style={{ marginLeft: 10 }}
                  className="button-vermais"
                  to={"/apps/productivity/premium/?cupom=FLY25"}
                >
                  ASSINATURA PREMIUM [25%OFF]
                </Link>
              </Row>
            </div>
          </RowAndSpaceBetween>
        </div>
      )}
      {(!app || !app.includes("finances")) && (
        <div style={{ marginTop: 20 }}>
          <RowAndSpaceBetween>
            <img
              src={require("../assets/images/app/finances/main.png")}
              alt="aplicativo"
              height={150}
            />
            <div style={{ minWidth: 330 }}>
              <h3>{t("flynow_finances", language)}</h3>
              <Text style={{ marginTop: 10, fontSize: "1rem" }}>
                {t("flynow_finances_desc", language)}
              </Text>
              <Row>
                <Link className="button-vermais" to={"/apps/finances" + query}>
                  BAIXAR APP
                </Link>
                <Link
                  style={{ marginLeft: 10 }}
                  className="button-vermais"
                  to={"/apps/finances/premium/?cupom=FLY25"}
                >
                  ASSINATURA PREMIUM [25%OFF]
                </Link>
              </Row>
            </div>
          </RowAndSpaceBetween>
        </div>
      )}
      {(!app || !app.includes("phrases")) && (
        <div style={{ marginTop: 20 }}>
          <RowAndSpaceBetween>
            <img
              src={require("../assets/images/app/frases/app-frases.png")}
              alt="aplicativo"
              height={150}
            />
            <div style={{ minWidth: 330 }}>
              <h3>{t("flynow_frases", language)}</h3>
              <Text style={{ marginTop: 10, fontSize: "1rem" }}>
                {t("flynow_frases_desc", language)}
              </Text>
              <Row>
                <Link className="button-vermais" to={"/apps/phrases" + query}>
                  BAIXAR APP
                </Link>
                <a
                  style={{ marginLeft: 10 }}
                  className="button-vermais"
                  href="https://buy.stripe.com/dR615R9Cp6u1bRudQZ?locale=pt-BR&prefilled_promo_code=FLYN25"
                >
                  PREMIUM VILALÍCIO [25%OFF]
                </a>
              </Row>
            </div>
          </RowAndSpaceBetween>
        </div>
      )}
    </Page>
  )
}

export default Bonus
